export default defineAppConfig({
  ui: {
    primary: "primary",
    popover: {
      wrapper: "relative",
      container: "z-20",
      width: "",
      background: "bg-white dark:bg-midnight",
      shadow: "shadow-ui",
      rounded: "rounded",
      ring: "ring-0",
      base: "overflow-hidden focus:outline-none ml-64",
    },
    pagination: {
      wrapper: "flex items-center -space-x-px",
      base: "",
      rounded: "",
      default: {
        size: "sm",
        activeButton: {
          class:
            "border-b-2 border-green-500 pt-2 dark:text-white text-midnight",
        },
        inactiveButton: {
          class:
            "dark:text-white text-midnight !border-0 focus:ring-0 ring-0 !bg-transparent",
        },
        prevButton: {
          class: "dark:text-black disabled:hidden",
          icon: "i-heroicons-arrow-small-left-20-solid",
        },
        nextButton: {
          class: "dark:text-black disabled:hidden",
          icon: "i-heroicons-arrow-small-right-20-solid",
        },
      },
    },
    table: {
      wrapper: "relative overflow-x-auto",
      base: "min-w-full table-fixed",
      divide: "",
      thead: "rounded",
      tbody: "divide-y divide-[#EBEBEB] dark:divide-gray-800",
      tr: {
        base: "hover:bg-[#F6F6F6] dark:hover:bg-[#101827]",
        selected: "bg-gray-50 dark:bg-gray-800/50",
        active: "hover:bg-gray-50 dark:hover:bg-gray-800/50 cursor-pointer",
      },
      th: {
        base: "text-left rtl:text-right bg-[#F6F6F6] dark:bg-[#101827]",
        padding: "px-3 py-2",
        color: "text-[#6B6B6C] dark:text-white",
        font: "font-medium",
        size: "text-xs",
      },
      td: {
        base: "whitespace-nowrap ",
        padding: "px-3 py-4",
        color: "",
        font: "",
        size: "text-sm",
      },
      checkbox: {
        padding: "ps-4",
      },
      loadingState: {
        wrapper:
          "flex flex-col items-center justify-center flex-1 px-6 py-14 sm:px-14",
        label: "text-sm text-center text-gray-900 dark:text-white",
        icon: "w-6 h-6 mx-auto text-gray-400 dark:text-gray-500 mb-4 animate-spin",
      },
      emptyState: {
        wrapper:
          "flex flex-col items-center justify-center flex-1 px-6 py-14 sm:px-14",
        label: "text-sm text-center text-gray-900 dark:text-white",
        icon: "w-6 h-6 mx-auto text-gray-400 dark:text-gray-500 mb-4",
      },
      default: {
        sortAscIcon: "i-heroicons-arrow-small-up-20-solid",
        sortDescIcon: "i-heroicons-arrow-small-down-20-solid",
        sortButton: {
          icon: "i-heroicons-arrows-up-down-20-solid",
          trailing: true,
          square: true,
          class: "-m-1.5 font-normal text-xs ",
        },
        loadingState: {
          icon: "i-heroicons-arrow-path-20-solid",
          label: "Loading...",
        },
        emptyState: {
          icon: "i-heroicons-circle-stack-20-solid",
          label: "No items.",
        },
      },
    },
    slideover: {
      wrapper: "fixed inset-0 flex z-[55]",
      overlay: {
        base: "fixed inset-0 transition-opacity",
        background: "bg-midnight/60 backdrop-blur-sm",
      },
      base: "relative flex-1 flex flex-col w-full focus:outline-none rounded-md overflow-y-auto my-2 mx-2 px-6 py-4 dark:bg-midnight bg-primaryBG",
      shadow: "shadow-ui",
      width: "w-screen max-w-md",
    },
    modal: {
      width: "w-full sm:max-w-2xl",
      overlay: {
        base: "fixed inset-0 transition-opacity",
        background: "bg-midnight/60 backdrop-blur-sm",
        transition: {
          enter: "ease-out duration-300",
          enterFrom: "opacity-0",
          enterTo: "opacity-100",
          leave: "ease-in duration-200",
          leaveFrom: "opacity-100",
          leaveTo: "opacity-0",
        },
      },
    },
    avatar: {
      wrapper: "relative inline-flex items-center justify-center",
      background: "bg-gray-100 dark:bg-gray-800",
      rounded: "rounded-full",
      placeholder:
        "font-medium leading-none text-gray-900 dark:text-white truncate",
      size: {
        "3xs": "h-4 w-4 text-[8px]",
        "2xs": "h-5 w-5 text-[10px]",
        xs: "h-6 w-6 text-[11px]",
        sm: "h-8 w-8 text-xs",
        md: "h-10 w-10 text-sm",
        lg: "h-12 w-12 text-base",
        xl: "h-14 w-14 text-lg",
        "2xl": "h-16 w-16 text-xl",
        "3xl": "h-20 w-20 text-2xl",
        "5xl": "h-32 w-32 text-4xl",
      },
      chip: {
        base: "absolute rounded-full ring-1 ring-white dark:ring-gray-900 flex items-center justify-center text-white dark:text-gray-900 font-medium",
        background: "bg-{color}-500 dark:bg-{color}-400",
        position: {
          "top-right": "top-0 right-0",
          "bottom-right": "bottom-0 right-0",
          "top-left": "top-0 left-0",
          "bottom-left": "bottom-0 left-0",
        },
        size: {
          "3xs": "h-[4px] min-w-[4px] text-[4px] p-px",
          "2xs": "h-[5px] min-w-[5px] text-[5px] p-px",
          xs: "h-1.5 min-w-[0.375rem] text-[6px] p-px",
          sm: "h-2 min-w-[0.5rem] text-[7px] p-0.5",
          md: "h-2.5 min-w-[0.625rem] text-[8px] p-0.5",
          lg: "h-3 min-w-[0.75rem] text-[10px] p-0.5",
          xl: "h-3.5 min-w-[0.875rem] text-[11px] p-1",
          "2xl": "h-4 min-w-[1rem] text-[12px] p-1",
          "3xl": "h-5 min-w-[1.25rem] text-[14px] p-1",
        },
      },
      default: {
        size: "sm",
        chipColor: null,
        chipPosition: "top-right",
      },
    },
    commandPalette: {
      wrapper:
        "flex flex-col flex-1 min-h-0 divide-y divide-gray-100 dark:divide-gray-800 shadow-ui bg-white dark:bg-darkFormBG rounded border border-[#E4E7EB] dark:border-[#374151] ",
      container:
        "relative flex-1 overflow-y-auto divide-y divide-gray-100 dark:divide-gray-800 scroll-py-2",
      input: {
        wrapper: "relative flex items-center",
        base: "w-full placeholder-gray-400 dark:placeholder-gray-500 bg-transparent border-0 text-gray-900 dark:text-white focus:ring-0 focus:outline-none",
        padding: "px-4",
        height: "h-12",
        size: "sm:text-sm",
        icon: {
          base: "pointer-events-none absolute start-4 text-gray-400 dark:text-gray-500",
          size: "h-4 w-4",
          padding: "ps-10",
        },
        closeButton: "absolute end-4",
      },
      emptyState: {
        wrapper:
          "flex flex-col items-center justify-center flex-1 px-6 py-14 sm:px-14",
        label: "text-sm text-center text-gray-900 dark:text-white",
        queryLabel: "text-sm text-center text-gray-900 dark:text-white",
        icon: "w-6 h-6 mx-auto text-gray-400 dark:text-gray-500 mb-4",
      },
      group: {
        wrapper: "p-2",
        label:
          "px-6 curser-pointer my-2 text-xs font-semibold text-gray-900 dark:text-white",
        container: "text-sm text-gray-700 dark:text-gray-200",
        command: {
          base: "flex justify-between select-none items-center rounded-md px-2 py-1.5 gap-2 relative",
          active: "bg-gray-100 dark:bg-gray-800 text-gray-900 dark:text-white",
          inactive: "",
          label: "flex items-center gap-3 min-w-0",
          prefix: "text-gray-400 dark:text-gray-500",
          suffix: "text-gray-400 dark:text-gray-500",
          container: "flex items-center gap-2 min-w-0",
          icon: {
            base: "flex-shrink-0 w-4 h-4",
            active: "text-gray-900 dark:text-white",
            inactive: "text-gray-400 dark:text-gray-500",
          },
          selectedIcon: {
            base: "h-4 w-4 text-gray-900 dark:text-white flex-shrink-0",
          },
          avatar: {
            base: "flex-shrink-0",
            size: "3xs",
          },
          chip: {
            base: "flex-shrink-0 w-2 h-2 mx-1 rounded-full",
          },
          disabled: "opacity-50",
          shortcuts: "hidden md:inline-flex flex-shrink-0 gap-0.5",
        },
        active: "flex-shrink-0 text-gray-500 dark:text-gray-400",
        inactive: "flex-shrink-0 text-gray-500 dark:text-gray-400",
      },
      default: {
        icon: "i-heroicons-magnifying-glass-20-solid",
        loadingIcon: "i-heroicons-arrow-path-20-solid",
        emptyState: {
          icon: "i-heroicons-magnifying-glass-20-solid",
          label: "We couldn't find any items.",
          queryLabel:
            "We couldn't find any items with that term. Please try again.",
        },
        closeButton: null,
        selectedIcon: "i-heroicons-check-20-solid",
      },
    },
    toggle: {
      base: "relative inline-flex h-5 w-9 flex-shrink-0 border-2 border-transparent disabled:cursor-not-allowed disabled:opacity-50 focus:outline-none",
      rounded: "rounded-full",
      ring: "focus-visible:ring-2 focus-visible:ring-gray-500 dark:focus-visible:ring-gray-400 focus-visible:ring-offset-2 focus-visible:ring-offset-white dark:focus-visible:ring-offset-gray-900",
      active: "bg-ngreen dark:bg-ngreen",
      inactive: "bg-gray-200 dark:bg-gray-600",
      container: {
        base: "pointer-events-none relative inline-block rounded-full bg-white dark:bg-gray-900 shadow transform ring-0 transition ease-in-out duration-200",
        active: {
          "2xs": "translate-x-2 rtl:-translate-x-2",
          xs: "translate-x-2.5 rtl:-translate-x-2.5",
          sm: "translate-x-3 rtl:-translate-x-3",
          md: "translate-x-4 rtl:-translate-x-4",
          lg: "translate-x-5 rtl:-translate-x-5",
          xl: "translate-x-6 rtl:-translate-x-6",
          "2xl": "translate-x-7 rtl:-translate-x-7",
        },
        inactive: "translate-x-0 rtl:-translate-x-0",
        size: {
          "2xs": "h-2 w-2",
          xs: "h-2.5 w-2.5",
          sm: "h-3 w-3",
          md: "h-4 w-4",
          lg: "h-5 w-5",
          xl: "h-6 w-6",
          "2xl": "h-7 w-7",
        },
      },
      icon: {
        base: "absolute inset-0 h-full w-full flex items-center justify-center transition-opacity",
        active: "opacity-100 ease-in duration-200",
        inactive: "opacity-0 ease-out duration-100",
        on: "h-3 w-3 text-{color}-500 dark:text-{color}-400",
        off: "h-3 w-3 text-gray-400 dark:text-gray-500",
      },
      default: {
        onIcon: null,
        offIcon: null,
        color: "primary",
      },
    },
  },
});
