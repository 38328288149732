import revive_payload_client_AcAZhCiCfR from "/opt/build/repo/node_modules/.pnpm/nuxt@3.10.1_@parcel+watcher@2.5.0_@types+node@22.10.0_magicast@0.3.5_rollup@4.27.4_sass@1.81._pfj6gqr34cjbzfe4txeghs456m/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_qjO3TXFR5D from "/opt/build/repo/node_modules/.pnpm/nuxt@3.10.1_@parcel+watcher@2.5.0_@types+node@22.10.0_magicast@0.3.5_rollup@4.27.4_sass@1.81._pfj6gqr34cjbzfe4txeghs456m/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_4joiKyb4JQ from "/opt/build/repo/node_modules/.pnpm/nuxt@3.10.1_@parcel+watcher@2.5.0_@types+node@22.10.0_magicast@0.3.5_rollup@4.27.4_sass@1.81._pfj6gqr34cjbzfe4txeghs456m/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_E59VXrTaTw from "/opt/build/repo/node_modules/.pnpm/nuxt@3.10.1_@parcel+watcher@2.5.0_@types+node@22.10.0_magicast@0.3.5_rollup@4.27.4_sass@1.81._pfj6gqr34cjbzfe4txeghs456m/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_BPJ9YkYnFC from "/opt/build/repo/node_modules/.pnpm/nuxt@3.10.1_@parcel+watcher@2.5.0_@types+node@22.10.0_magicast@0.3.5_rollup@4.27.4_sass@1.81._pfj6gqr34cjbzfe4txeghs456m/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import plugin_vue3_XBXfJxWS7p from "/opt/build/repo/node_modules/.pnpm/@pinia+nuxt@0.5.5_magicast@0.3.5_rollup@4.27.4_typescript@5.7.2_vue@3.5.13_typescript@5.7.2_/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/opt/build/repo/.nuxt/components.plugin.mjs";
import prefetch_client_YX8HhM2fnM from "/opt/build/repo/node_modules/.pnpm/nuxt@3.10.1_@parcel+watcher@2.5.0_@types+node@22.10.0_magicast@0.3.5_rollup@4.27.4_sass@1.81._pfj6gqr34cjbzfe4txeghs456m/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_oQjswUVxeH from "/opt/build/repo/node_modules/.pnpm/nuxt-bugsnag@7.3.0_@bugsnag+core@7.25.0_magicast@0.3.5_rollup@4.27.4/node_modules/nuxt-bugsnag/dist/runtime/client/plugin.mjs";
import slideovers_7AQhI99YOc from "/opt/build/repo/node_modules/.pnpm/@nuxt+ui@2.19.2_axios@1.6.7_change-case@5.4.4_focus-trap@7.6.2_magicast@0.3.5_rollup@4.27.4_v_2mngrnusaotukqdfvvws3nmsku/node_modules/@nuxt/ui/dist/runtime/plugins/slideovers.js";
import modals_8J0xWpxVmm from "/opt/build/repo/node_modules/.pnpm/@nuxt+ui@2.19.2_axios@1.6.7_change-case@5.4.4_focus-trap@7.6.2_magicast@0.3.5_rollup@4.27.4_v_2mngrnusaotukqdfvvws3nmsku/node_modules/@nuxt/ui/dist/runtime/plugins/modals.js";
import colors_lWr70MBOqF from "/opt/build/repo/node_modules/.pnpm/@nuxt+ui@2.19.2_axios@1.6.7_change-case@5.4.4_focus-trap@7.6.2_magicast@0.3.5_rollup@4.27.4_v_2mngrnusaotukqdfvvws3nmsku/node_modules/@nuxt/ui/dist/runtime/plugins/colors.js";
import plugin_client_LqwtDNIB51 from "/opt/build/repo/node_modules/.pnpm/@nuxtjs+color-mode@3.5.2_magicast@0.3.5_rollup@4.27.4/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.js";
import plugin_kO4JZqTL6o from "/opt/build/repo/node_modules/.pnpm/@nuxt+icon@1.8.2_magicast@0.3.5_rollup@4.27.4_vite@5.0.12_@types+node@22.10.0_sass@1.81.0_ter_5mmaggfvvkx6mexdhv2l44jewm/node_modules/@nuxt/icon/dist/runtime/plugin.js";
import formkitPlugin_pZqjah0RUG from "/opt/build/repo/.nuxt/formkitPlugin.mjs";
import chunk_reload_client_MrtLQvMOPA from "/opt/build/repo/node_modules/.pnpm/nuxt@3.10.1_@parcel+watcher@2.5.0_@types+node@22.10.0_magicast@0.3.5_rollup@4.27.4_sass@1.81._pfj6gqr34cjbzfe4txeghs456m/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import axios_QMFgzss1s4 from "/opt/build/repo/plugins/axios.ts";
import echo_Too0shxWI0 from "/opt/build/repo/plugins/echo.ts";
import i18n_VfGcjrvSkj from "/opt/build/repo/plugins/i18n.ts";
import plyr_m1vubbxwTm from "/opt/build/repo/plugins/plyr.ts";
import stripe_6FZUdDHpIZ from "/opt/build/repo/plugins/stripe.ts";
import vue_final_modal_pML93k5qcp from "/opt/build/repo/plugins/vue-final-modal.ts";
import vue_query_wrmMkNpEpe from "/opt/build/repo/plugins/vue-query.ts";
export default [
  revive_payload_client_AcAZhCiCfR,
  unhead_qjO3TXFR5D,
  router_4joiKyb4JQ,
  payload_client_E59VXrTaTw,
  check_outdated_build_client_BPJ9YkYnFC,
  plugin_vue3_XBXfJxWS7p,
  components_plugin_KR1HBZs4kY,
  prefetch_client_YX8HhM2fnM,
  plugin_oQjswUVxeH,
  slideovers_7AQhI99YOc,
  modals_8J0xWpxVmm,
  colors_lWr70MBOqF,
  plugin_client_LqwtDNIB51,
  plugin_kO4JZqTL6o,
  formkitPlugin_pZqjah0RUG,
  chunk_reload_client_MrtLQvMOPA,
  axios_QMFgzss1s4,
  echo_Too0shxWI0,
  i18n_VfGcjrvSkj,
  plyr_m1vubbxwTm,
  stripe_6FZUdDHpIZ,
  vue_final_modal_pML93k5qcp,
  vue_query_wrmMkNpEpe
]